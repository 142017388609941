/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import PropTypes from 'prop-types'

import { UIIcon, UIButton, UILayout, UIText } from '../UI'
import { olderBrowser } from 'bora/src/utils/browserUtils'
import { getStyle, isLiinilaevad } from 'bora/src/utils/liinilaevadThemeUtils'
import { getGavdosStyle, isGavdos } from 'bora/src/utils/gavdosStyleUtils'

const xsStyles = {
  margin0: { margin: '0' },
  container: { padding: '18px 16px', column: true, style: { 'flex-direction': 'column' } },
  prevTitleBlock: { margin: '32px 0', style: { order: 3 } },
  prevTitleText: { whiteSpace: 'normal', size: '16px', margin: '0 0 0 10px' },
  goToNextRow: { padding: '0', style: { order: 2 } },
  goToNextButton: { width: '100%', margin: '5px 0 0 0' },
  goToNextTitleBlock: { center: true, margin: '0', width: '100%', padding: '24px' },
  spaceBtw: { flexBasis: '0', justifyContent: 'space-between' },
}

// eslint-disable-next-line complexity
const BottomSlider = (props, context) => {
  const { muiTheme } = context

  const {
    isNextAvailable,
    prevAction,
    prevTitle,
    nextAction,
    nextTitle,
    nextSubTitle,
    centralElement,
    editMode,
    buttonStyle,
    isPopup,
    isEditPopup,
  } = props

  const buttonNextTextStyle = editMode
    ? { size: muiTheme.smallerSize || getStyle('24px', '18px'), textTransform: 'uppercase' }
    : { size: '24px' }

  const additionalStyles = isLiinilaevad ? { width: '367px', height: '66px' } : { width: '367px', height: '88px' }

  const buttonNextStyle = editMode
    ? { width: getStyle('120px', '210px'), height: getStyle(isEditPopup && '71px', '55px') }
    : additionalStyles

  return (
    <UILayout
      center
      j-flex-center={isPopup}
      padding={editMode ? '24px 0' : '30px'}
      sm={xsStyles.container}
      data-testid="bottom-slider"
    >
      <UILayout
        flex-start
        width="auto"
        data-testid="go-to-prev"
        sm={xsStyles.prevTitleBlock}
        style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.backButton }) }}
      >
        <UILayout
          cursor="pointer"
          center
          data-testid="go-to-prev-button-wrapper"
          border={getStyle('1px solid #585858')}
          borderRadius={getStyle('12px')}
          bgColor={getStyle('#EDEDED')}
          padding={getStyle(isEditPopup ? '20px' : '12px')}
          onClick={prevAction}
          margin={editMode ? '0 20px 0 0' : '0'}
          width={getGavdosStyle('367px', '100%')}
          j-flex-center={isGavdos}
          sm={xsStyles.margin0}
        >
          <UIIcon fill={getStyle('#585858', '#79909B')} height="29px" type="iconGoBack" />
          <UIText
            width={getStyle(isPopup ? '100%' : '270px')}
            whiteSpace={getStyle('normal', olderBrowser || isGavdos ? 'unset' : 'nowrap')}
            margin="0 0 0 20px"
            letterSpacing="0.5px"
            color={getStyle('#585858', '#79909b')}
            size={getStyle('16px', '18px')}
            textTransform="uppercase"
            font={getStyle(muiTheme.secondFontFamily, 'GinesoNorm')}
            sm={xsStyles.prevTitleText}
          >
            {prevTitle}
          </UIText>
        </UILayout>
      </UILayout>

      {centralElement}

      <UILayout
        j-flex-end
        width={isGavdos && centralElement ? 'auto' : '100%'}
        sm={xsStyles.goToNextRow}
        xs={{
          width: '100%',
        }}
      >
        <UIButton
          id="buttonStyle"
          click={nextAction}
          disabled={!isNextAvailable}
          height={getStyle('66px !important')}
          {...buttonNextStyle}
          {...buttonStyle}
          {...muiTheme.ids.buttonStyles}
          {...muiTheme.ids.buttonActionStyles}
          sm={xsStyles.goToNextButton}
          data-testid="go-to-next"
          {...(muiTheme.ids.buttonActionStyles && {
            ...muiTheme.ids.buttonActionStyles.nextButton,
            ...getStyle(isEditPopup ? { width: '100%' } : {}, {}),
          })}
          borderRadius={getStyle(
            '12px',
            muiTheme.ids.buttonActionStyles &&
              muiTheme.ids.buttonActionStyles.nextButton &&
              muiTheme.ids.buttonActionStyles.nextButton.borderRadius
              ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
              : 'inherit'
          )}
        >
          <UILayout
            center
            margin="0 24px"
            lineHeight="30px"
            height="100%"
            width="auto"
            style={xsStyles.spaceBtw}
            sm={xsStyles.goToNextTitleBlock}
          >
            <UILayout
              column
              width={olderBrowser ? '100%' : 'auto'}
              style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.subtitles }) }}
            >
              <UIText
                id="buttonLabelTextStyle"
                align="left"
                size={getStyle('24px', '18px')}
                opacity="0.5"
                font={getStyle('SourceSansPro, Sans-Serif', 'GinesoNorm')}
                margin={
                  muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.subtitles
                    ? muiTheme.ids.buttonActionStyles.subtitles.margin
                    : '0'
                }
              >
                {nextSubTitle}
              </UIText>
              <UIText
                id="buttonTextStyle"
                align={getStyle(isEditPopup ? 'center' : 'left', 'center')}
                {...buttonNextTextStyle}
                font={
                  muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.titles
                    ? muiTheme.ids.buttonActionStyles.titles.fontFamily
                    : 'inherit'
                }
                {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
              >
                {nextTitle}
              </UIText>
            </UILayout>
            <UIIcon
              display-if={!isPopup || !isLiinilaevad}
              fill="white"
              type={!editMode ? 'selectTicketArrow' : 'check'}
            />
          </UILayout>
        </UIButton>
      </UILayout>
    </UILayout>
  )
}

BottomSlider.propTypes = {
  isNextAvailable: PropTypes.bool.isRequired,
  prevAction: PropTypes.func.isRequired,
  prevTitle: PropTypes.string.isRequired,
  nextAction: PropTypes.func.isRequired,
  nextTitle: PropTypes.string.isRequired,
  nextSubTitle: PropTypes.string,
  centralElement: PropTypes.any,
  editMode: PropTypes.bool,
  buttonStyle: PropTypes.any,
  size: PropTypes.any,
  isPopup: PropTypes.bool,
  isEditPopup: PropTypes.bool,
}

BottomSlider.defaultProps = {
  editMode: false,
  buttonStyle: false,
  centralElement: null,
  nextSubTitle: '',
  size: '18px',
  isPopup: false,
  isEditPopup: false,
}

BottomSlider.contextTypes = {
  muiTheme: PropTypes.object,
}

export default BottomSlider
