import React from 'react'
import { inc, dec } from 'ramda'
import PropTypes from 'prop-types'
import { UIButton, UIIcon, UILayout, UIText } from '../UI'

const incDecInputStyle = {
  width: '30px',
  height: '22px',
  lineHeight: '20px',
  padding: '5px',
  margin: '0 5px',
  borderRadius: '5px',
  border: '1px solid #ddd',
  fontSize: '18px',
  textAlign: 'center',
}

const overflowWarningStyle = {
  position: 'absolute',
  size: '12px',
  family: 'GinesoNormDem,Fjalla One,sans-serif',
  weight: 'bold',
  color: '#e23838',
  margin: '5px 0 0',
}

const getIncDecButtonParameters = (props, context) => {
  const { muiTheme } = context
  const { id, ...params } = props
  let customThemeProps = {}
  if (muiTheme) customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    initValue: 0,
    minValue: 0,
    maxValue: Infinity,
    disabled: false,
  }

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params,
  }

  return internals
}

class IncDecButton extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { minValue, maxValue, initValue } = getIncDecButtonParameters(props, context)
    const value = Math.max(Math.min(initValue, maxValue), minValue)

    this.state = {
      value,
      prevValue: 0,
      showOverflowWarning: false,
    }
  }

  componentDidMount() {
    const { initValue } = getIncDecButtonParameters(this.props, this.context)
    this.setState({
      value: initValue,
      showOverflowWarning: false,
    })
  }

  // commented out due to https://trello.com/c/ll9B3F2l
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.value !== this.props.initValue) {
  //     this.setState({
  //       value: this.props.initValue,
  //     })
  //   }
  // }

  handleTouchTap = (type) => {
    const { select, minValue, maxValue } = getIncDecButtonParameters(this.props, this.context)

    let curValue = this.state.value
    let showOverflowWarning = false

    switch (type) {
      case 'add':
        curValue = inc(curValue)
        break
      case 'remove':
        curValue = dec(curValue)
        break
      default:
        break
    }

    const totalMax = maxValue

    if (curValue >= totalMax) showOverflowWarning = true
    curValue = Math.max(Math.min(curValue, totalMax), minValue)

    this.setState({
      value: curValue,
      showOverflowWarning,
    })

    if (select) select(curValue)
  }

  handleChange(event) {
    const curValue = event.target.value
    if (curValue === '' || Number.isNaN(curValue)) return
    const { select, minValue, maxValue } = getIncDecButtonParameters(this.props, this.context)

    let showOverflowWarning = false

    const currentValueNum = Math.abs(Number(curValue))
    const totalMax = maxValue

    const value = Math.max(Math.min(currentValueNum, totalMax), minValue)

    if (currentValueNum >= totalMax) {
      showOverflowWarning = true
    }

    this.setState(() => ({ value, showOverflowWarning }))
    if (select) select(value)
  }

  handleFocus(event) {
    const prevValue = event.target.value === '' ? 0 : Number(event.target.value)
    const nextValue = event.target.value === '0' ? '' : Number(event.target.value)
    this.setState({ prevValue, value: nextValue })
  }

  handleBlur(event) {
    const curValue = event.target.value === '' ? 0 : event.target.value
    this.setState({ value: curValue })
  }

  render() {
    let align = 'left'
    const {
      disabled,
      minValue,
      maxValue,
      testPrefix,
      passengerTicketsCount,
      maxAvailableMessage,
      disabledInput,
      ...params
    } = getIncDecButtonParameters(this.props, this.context)

    align = align === 'left' ? 'j-flex-start' : align
    align = align === 'right' ? 'j-flex-end' : align
    align = align === 'center' ? 'j-flex-center' : align
    const obj = {}
    obj[align] = '0'
    const { muiTheme } = this.context

    const InternalFloatingActionButton = (props) => {
      // eslint-disable-next-line react/prop-types,no-shadow
      const { type, ...params } = props
      return (
        <UIButton
          id="IncDecButton"
          type={'circle'}
          {...params}
          click={() => this.handleTouchTap(type, maxValue)}
          {...muiTheme.ids.buttonStyles}
          {...muiTheme.ids.TicketsButtonSizes}
        >
          <UIIcon fill={'white'} type={type} {...muiTheme.ids.TicketsButtonIconSizes} />
        </UIButton>
      )
    }

    const { value } = this.state
    let disableDec = value <= 0
    let disableInc = value >= maxValue

    if (disabled) {
      disableDec = true
      disableInc = true
    }

    return (
      <UILayout flex-center column center {...obj} width="auto">
        <UILayout center>
          <InternalFloatingActionButton
            data-testid={`${testPrefix}-minus`}
            type={'remove'}
            {...params}
            disabled={disableDec}
          />
          <input
            style={incDecInputStyle}
            type="tel"
            maxLength={3}
            value={this.state.value}
            onChange={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            disabled={disabledInput}
          />
          <InternalFloatingActionButton
            data-testid={`${testPrefix}-plus`}
            type={'add'}
            {...params}
            disabled={disableInc}
          />
        </UILayout>
        <UILayout height="15px" column center display-if={this.state.showOverflowWarning}>
          <UIText {...overflowWarningStyle}>{maxAvailableMessage}</UIText>
        </UILayout>
      </UILayout>
    )
  }
}

IncDecButton.contextTypes = {
  muiTheme: PropTypes.object,
}

IncDecButton.propTypes = {
  select: PropTypes.func.isRequired,
  disabledInput: PropTypes.bool,
}

export default IncDecButton
