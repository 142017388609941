import React from 'react'
import PropTypes from 'prop-types'
import { UIIcon, UILayout, UIText } from '../../UI'
import { THEME } from '../../../themes'
import { getStyle, isLiinilaevad } from 'bora/src/utils/liinilaevadThemeUtils'

const styles = {
  iconArrow: { width: '10px', height: '15px', fill: '#9b9b9b', margin: '0' },
}

export const timeWarningNote = '*'

let departureStyle = {}
let departureStyleXs = {}

const xsStyles = {
  iconArrow: { width: '10px', height: '10px', margin: '0' },
  time: {
    width: 'auto',
    ...(window.brandProps.theme === THEME.LIINILAEVAD && { margin: '0' }),
  },
  iconArrowXS: { margin: 0, width: '18px', height: '18px' },
  mobileMargin: { margin: 0 },
}

class Time extends React.PureComponent {
  static propTypes = {
    departureTime: PropTypes.string.isRequired,
    arrivalTime: PropTypes.string.isRequired,
    isClosed: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isCancelled: PropTypes.bool,
    isDangerous: PropTypes.bool,
    isHovercraft: PropTypes.bool,
    isRestrictedPrices: PropTypes.bool,
  }

  render() {
    const {
      departureTime,
      arrivalTime,
      isClosed,
      isSelected,
      isCancelled,
      isDangerous,
      isHovercraft,
      isRestrictedPrices,
    } = this.props
    let arrivalTimeColor = window.brandProps.theme === THEME.KIHNU ? '#94a8b2' : '#000000'
    let showArrivalTime = window.brandProps.theme !== THEME.KIHNU && window.brandProps.theme !== THEME.SAAREMAA

    if (window.brandProps.theme === THEME.KIHNU) {
      departureStyle = {
        padding: '0 1.5rem',
        'flex-start': true,
      }

      departureStyleXs = {
        padding: '0 0.2rem 0 0',
      }
    }

    let textColor =
      window.brandProps.theme === THEME.SAAREMAA && isSelected
        ? window.brandProps.muiTheme.palette.accent1Color
        : '#000000'

    let margin = window.brandProps.theme === THEME.GAVDOS ? '0 0 0 20px' : '0'

    if (window.brandProps.theme === THEME.LIINILAEVAD) {
      if (isSelected) {
        textColor = 'black'
        arrivalTimeColor = '#ffffff'
      }

      showArrivalTime = false

      departureStyle = {
        padding: getStyle('0 0.5rem 0 0', '0 1rem'),
        'flex-start': false,
      }

      departureStyleXs = {
        padding: '0 0 0 1rem',
      }

      margin = '0 0 0 4.4rem'
    }

    const cancelledTextColor = window.brandProps.muiTheme.palette && window.brandProps.muiTheme.palette.cancelled
    const getSize = (size) => (isLiinilaevad ? size : '18px')

    return (
      <UILayout
        data-testid="sail-time"
        column={window.brandProps.theme !== THEME.SAAREMAA && window.brandProps.theme !== THEME.KIHNU}
        center
        j-flex-center
        width={getStyle('auto', '100%')}
        margin={margin}
        xs={xsStyles.time}
      >
        <UILayout
          row
          center
          j-flex-start
          width="100%"
          {...departureStyle}
          xs={departureStyleXs}
          style={{ ...window.brandProps.muiTheme.ids.departure }}
        >
          <UIText
            size={getSize('20px')}
            lineHeight="1"
            align="left"
            color={isCancelled && window.brandProps.theme === THEME.SAAREMAA ? cancelledTextColor : textColor}
            margin={window.brandProps.theme === THEME.KIHNU ? '4px 0 0 0' : '0'}
            font={
              (window.brandProps.muiTheme.ids.departure && window.brandProps.muiTheme.ids.departure.customFont) ||
              isLiinilaevad
                ? window.brandProps.muiTheme.secondFontFamily
                : 'GinesoNorm'
            }
            xs={{
              ...xsStyles.size14,
              ...xsStyles.mobileMargin,
            }}
          >
            {departureTime}
          </UIText>
          <UIText
            display-if={isClosed && window.brandProps.theme !== THEME.SAAREMAA && !isLiinilaevad}
            color="#d0021b"
            weight="bold"
            margin="0 0 0 2px"
            font="GinesoNorm"
            size="18px"
          >
            {timeWarningNote}
          </UIText>
          <UILayout
            display-if={window.brandProps.theme === THEME.SAAREMAA}
            width="50px"
            height="25px"
            j-flex-space-between
            xs={{ width: '18px', height: '18px' }}
            {...(window.brandProps.muiTheme.ids &&
              window.brandProps.muiTheme.ids.sailIcons && { ...window.brandProps.muiTheme.ids.sailIcons })}
          >
            <UIIcon type="iconDanger" display-if={isDangerous} xs={{ width: '18px', height: '18px' }} />
            <UIIcon type="iconCancelledSail" display-if={isCancelled} xs={{ width: '18px', height: '18px' }} />
          </UILayout>
          <UILayout
            display-if={window.brandProps.theme === THEME.KIHNU}
            width="100px"
            height="25px"
            xs={{ width: '18px', height: '18px' }}
            {...(window.brandProps.muiTheme.ids &&
              window.brandProps.muiTheme.ids.sailIcons && { ...window.brandProps.muiTheme.ids.sailIcons })}
            data-testid="additional-note"
          >
            {isHovercraft && (
              <UIIcon
                data-testid="hovercraft-icon"
                type="iconHovercraft"
                width={'24px'}
                height={'24px'}
                xs={{ width: '18px', height: '18px' }}
                style={{ marginLeft: '10px' }}
                margin={'0 10px 0 0'}
              />
            )}
            {isCancelled && (
              <UIIcon
                data-testid="cancelled-icon"
                type="iconCancelledSail"
                width={'24px'}
                height={'24px'}
                xs={{ width: '18px', height: '18px' }}
                style={{ marginRight: '10px' }}
                margin={'0 10px 0 0'}
              />
            )}
            {isRestrictedPrices && (
              <UIIcon
                type="iconNoHeavyTrucks"
                data-testid="no-heavy-trucks-icon"
                width={'24px'}
                height={'24px'}
                xs={{ width: '18px', height: '18px' }}
                style={{ marginRight: '10px' }}
                margin={'0 10px 0 0'}
              />
            )}
            {isDangerous && (
              <UIIcon
                data-testid="danger-icon"
                type="iconDanger"
                width={'24px'}
                height={'24px'}
                xs={{ width: '18px', height: '18px' }}
                style={{ marginRight: '10px' }}
                margin={'0 10px 0 0'}
              />
            )}
          </UILayout>
          <UIIcon
            display-if={showArrivalTime}
            type="iconChevron"
            margin="0 6px"
            style={styles.iconArrow}
            xs={xsStyles.iconArrowXS}
            xsl={xsStyles.iconArrow}
          />
          <UIText
            display-if={showArrivalTime}
            size="18px"
            lineHeight="1"
            align="left"
            color={arrivalTimeColor}
            margin="0"
            font="GinesoNorm"
            xs={xsStyles.size14}
          >
            {arrivalTime}
          </UIText>
        </UILayout>
      </UILayout>
    )
  }
}

export default Time
